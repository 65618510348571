module.exports = [{
      plugin: require('/Users/vimal/lib/bazr-blog/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":800,"quality":100},
    },{
      plugin: require('/Users/vimal/lib/bazr-blog/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-105768695-3","head":false,"anonymize":true,"respectDNT":true,"exclude":["/preview/**"],"pageTransitionDelay":0,"sampleRate":5,"siteSpeedSampleRate":10,"cookieDomain":"bazr.io"},
    },{
      plugin: require('/Users/vimal/lib/bazr-blog/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
